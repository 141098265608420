import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "./assets/scss/main.scss";
import {Route, Routes, useLocation} from "react-router-dom";
import AuthContextProvider, {useAuth} from "./context/AuthContext";
import ResetPass from "./page/ResetPass"
import ResetPassword from "./page/ResetPassword"
import SetupCloudDrive from "./page/SetupCloudDrive";
import Activation from "./page/Activation";
import CancelSubscribe from "./page/CancelSubscribe";
import AddCloudAccount from "./page/AddCloudAccount";
import LandingPage from "./page/CreateAmoveTeam";
import AutoLogin from "./page/AutoLogin";
import PrivateRoutes from "./util/PrivateRoute";
import LoginPage from "./page/LoginPage";
import SSOLoginPage from "./page/SSOLoginPage";
import SignupPage from "./page/SignupPage";
import EmailVerificationNew from "./page/EmailVerificationNew";
import VerificationCode from "./page/VerificationCode";
import ForgotPassword from "./page/ForgotPassword";
import Dashboard from "./page/Dashboard";
import NewBackupInstance from "./page/NewBackupInstance";
import AmoveSearch from "./page/AmoveSearch";
import ActivityPage from "./page/ActivityPage";
import AmoveMigration from "./page/Migration";
import {DashboardHeader} from "./components/DashboardHeader";
import SubscriptionPlan from "./page/Subscription/SubscriptionPlan";
import BillingStatus from "./page/Billing/BillingStatus";
import SubscriptionSuccess from "./page/Subscription/SubscriptionSuccess";
import Materials from "./page/Materials";
import Users from "./page/Users";
import SingleSignProvider from "./page/SingleSignProvider";
import Groups from "./page/Groups";
import Project from "./page/Project";
import SharedCloudDrives from "./page/SharedCloudDrives";
import CompleteRegistration from "./page/CompleteRegistration";
import FileManagerNew from "./page/FileManagerNew";
import SSOCallBack from "./page/SSOCallBack";
import CreateAmoveTeam from "./page/CreateAmoveTeam";
import Onboarding from "./page/Onboarding-new";
import UserCallBack from "./page/UserCallback";
import LogsPage from "./page/LogsPage";
import ApiTokenPage from "./page/ApiTokenPage";
import TransferStatus from "./page/TransferStatus";
import SettingsPage from "./page/Setting/Settings";
import SecurityPage from "./page/Setting/Security";
import DriveSettings from "./page/DriveSettings";
import MFASetup from "./page/Setting/MFASetup";
import MFAVerificationCode from "./page/MFAVerificationCode";
import DropboxCallBack from "./page/DropboxCallBack";
import BoxCallBack from "./page/BoxCallBack";
import OneDriveCallBack from "./page/OneDriveCallBack";
import GoogleDriveCallBack from "./page/GoogleDriveCallBack";
import StorageDashboard from "./page/StorageDashboard";
import Buckets from "./page/Buckets";
import AccessKey from "./page/AccessKey";

function App() {
    const location = useLocation();
    return (
        <>
            <AuthContextProvider>
                <div className={`App ${location.pathname.replace('/', '')}`}>
                    <div className="dashboard-main">
                        <DashboardHeader/>
                        <Routes>
                            <Route element={<PrivateRoutes/>}>
                                <Route element={<Dashboard/>} path="/"/>
                                <Route path="/dashboard" element={<Dashboard/>}/>
                                <Route path="/storage-dashboard" element={<StorageDashboard/>}/>
                                <Route path="/buckets" element={<Buckets/>}/>
                                <Route path="/access-key" element={<AccessKey/>}/>
                                <Route element={<FileManagerNew/>} path="/file-manager-new"/>
                                <Route path="/subscribe" element={<SubscriptionPlan/>}/>
                                <Route path="/subscribe-success" element={<SubscriptionSuccess/>}/>
                                <Route path="/cancel-subscribe" element={<CancelSubscribe/>}/>
                                <Route path="/billing-status" element={<BillingStatus/>}/>
                                <Route path="/add-cloud-account" element={<AddCloudAccount/>}/>
                                <Route path="/setup-cloud-drive" element={<SetupCloudDrive/>}/>
                                <Route path="/new-backup-instance" element={<NewBackupInstance/>}/>
                                <Route path="/migration" element={<AmoveMigration/>}/>
                                <Route path="/search" element={<AmoveSearch/>}/>
                                <Route path="/activity" element={<ActivityPage/>}/>
                                <Route path="/materials" element={<Materials/>}/>
                                <Route path="/onboarding" element={<Onboarding/>}/>
                                <Route path="/landing" element={<LandingPage/>}/>
                                <Route path="/transfer-status" element={<TransferStatus/>}/>
                                <Route path="/sso" element={<SingleSignProvider/>}/>
                                <Route path="/users" element={<Users/>}/>
                                <Route path="/teams" element={<Groups/>}/>
                                <Route path="/projects" element={<Project/>}/>
                                <Route path="/shared-cloud-drive" element={<SharedCloudDrives/>}/>
                                <Route path="/create-team" element={<CreateAmoveTeam/>}/>
                                <Route path="/usercallback" element={<UserCallBack/>}/>
                                <Route path="/log" element={<LogsPage/>}/>
                                <Route path="/api-token" element={<ApiTokenPage/>}/>
                                <Route path="/account" element={<SettingsPage/>}/>
                                <Route path="/security" element={<SecurityPage/>}/>
                                <Route path="/drive-settings" element={<DriveSettings/>}/>
                                <Route path="/mfa-setup" element={<MFASetup/>}/>
                                <Route path="/dropbox" element={<DropboxCallBack/>}/>
                                <Route path="/box" element={<BoxCallBack/>}/>
                                <Route path="/onedrive" element={<OneDriveCallBack/>}/>
                                <Route path="/googledrive" element={<GoogleDriveCallBack/>}/>
                            </Route>
                            <Route element={<LoginPage/>} path="sign-in"/>
                            <Route element={<SSOLoginPage/>} path="sso-sign-in"/>
                            <Route element={<SSOCallBack/>} path="ssocallback"/>
                            <Route path="/forgot-password" element={<ForgotPassword/>}/>
                            <Route path="/signup" element={<SignupPage/>}/>
                            <Route path="/email-verification" element={<EmailVerificationNew/>}/>
                            <Route path="/verification-code" element={<VerificationCode/>}/>
                            <Route path="/reset-password" element={<ResetPass/>}/>
                            <Route path="/activation" element={<Activation/>}/>
                            <Route path="/reset-email" element={<ResetPassword/>}/>
                            <Route element={<AutoLogin/>} path="auto-sign-in"/>
                            <Route path="/register" element={<CompleteRegistration/>}/>
                            <Route path="/mfa" element={<MFAVerificationCode/>}/>
                        </Routes>
                    </div>
                </div>
            </AuthContextProvider>
        </>
    );
}

export default App;
