import {React, useEffect, useState, useRef} from 'react';
import {Navigate, useSearchParams, useNavigate} from "react-router-dom";
import {Button, Dropdown, Modal, Radio, Select, Tooltip, notification, Spin} from "antd";
import objectBucketIcon from "../assets/images/icons/object-bucket-icon.svg";
import objectCircleIcon from "../assets/images/icons/object-usage-icon.svg";
import objectFolderIcon from "../assets/images/icons/object-folder-icon.svg";
import usFlagIcon from "../assets/images/icons/us-flag-icon.svg";
import copyIcon from "../assets/images/icons/copy-white-icon.svg";
import trashIcon from "../assets/images/icons/trash-white-icon.svg";
import settingIcon from "../assets/images/icons/setting-white-icon.svg";
import {useAuth} from "../context/AuthContext";
import toastIcon from "../assets/images/icons/toast-icon.svg";
import crossIcon from "../assets/images/icons/cross-black-icon.svg";
import warning from "../assets/images/icons/warning-sign.svg";
import {LoadingOutlined} from "@ant-design/icons";
import pageLogoWhite from "../assets/images/logo-dark.svg";
import pageLogo from "../assets/images/logo.svg";
import caFlag from "../assets/images/flags/tn_ca-flag.gif";
import eiFlag from "../assets/images/flags/tn_ei-flag.gif";
import frFlag from "../assets/images/flags/tn_fr-flag.gif";
import gmFlag from "../assets/images/flags/tn_gm-flag.gif";
import ukFlag from "../assets/images/flags/tn_uk-flag.gif";
import usFlag from "../assets/images/flags/tn_us-flag.gif";
const StorageDashboard = () => {
    const {
        cloudAccounts,
        deleteDataWeb,
        setEndpoint,
        setShowPopupSupport,
        setPopup500,
        setError499,
        setPopup499,
        getDataWithTokenPOST,
        isDark,
        getDataWithToken,
        setLoading,
        loading,
        logout,
        getCloudAccounts,
        regions,
        getRegions,
        cloudAccountsDone,
        prettySize,
        limitExceeded
    } = useAuth();
    const navigate = useNavigate();
    const dataFetchedRef = useRef(false);
    const [api, contextHolder] = notification.useNotification();
    const [regionsFilter, setRegionsFilter] = useState([]);
    const [amoveStorage, setAmoveStorage] = useState([]);
    const [amoveStorageFiltered, setAmoveStorageFiltered] = useState([]);
    const [selectedRegionsFilter, setSelectedRegionsFilter] = useState("Select a Region");
    const [selectedRegions, setSelectedRegions] = useState("Select a Region");
    const [totalBuckets, setTotalBuckets] = useState(0);
    const [totalSize, setTotalSize] = useState(0);
    const [deleteRegion, setDeleteRegion] = useState(false);
    const [regionToDelete, setRegionToDelete] = useState({});
    const [loadingAmove, setLoadingAmove] = useState(false);
    const [showRegion, setShowRegion] = useState(false);
    const [showPageData, setShowPageData] = useState(false);
    const [iDriveUsage, setIDriveUsage] = useState(0);

    useEffect(() => {
        if(limitExceeded){
            navigate("/subscribe");
        }
    }, [limitExceeded]);
    const onShowRegion = () => {
        setShowRegion(true);
    };

    useEffect(() => {
        setTimeout(function () {
            if (cloudAccountsDone) {
                setShowPageData(true)
            }
        }, 1500)
    }, [cloudAccountsDone]);
    useEffect(() => {
        if (cloudAccounts.length > 0) {
            setAmoveStorage(cloudAccounts.filter((item) => (item.internalStorage)))
        }else{
            setAmoveStorage([])
            setAmoveStorageFiltered([])
        }
    }, [cloudAccounts]);


    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        getRegions();

        getDataWithToken("/api/cloudaccount/idrive_get_daily_usage")
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, response]) => {
                if (status === 401) {
                    setEndpoint("/api/cloudaccount/idrive_get_daily_usage")
                    setShowPopupSupport(true)
                    // logout()
                    setLoadingAmove(false);
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoadingAmove(false);
                    return;
                }
                if (status === 499) {
                    var data = JSON.parse(response)
                    var errorType = data.type;
                    setError499(data.errors[errorType][0])
                    setPopup499(true)
                    return;
                } else {
                    setIDriveUsage(response)
                }
            })
    }, []);
    useEffect(() => {
        if (amoveStorage.length > 0) {
            var tBuckets = 0;
            var tSize = 0;
            var regionName = [{
                label: "All",
                value: "all",
            }];
            amoveStorage.map((item) => {
                tBuckets += item.buckets.length;
                item.buckets.map((b) => {
                    tSize += b.size;
                })
                regionName.push({
                    label: item.name.substring(7, item.name.length - 1),
                    value: item.id,
                })
            })
            setTotalBuckets(tBuckets)
            setTotalSize(tSize)
            setRegionsFilter(regionName)
            setAmoveStorageFiltered(amoveStorage)
            console.log(amoveStorage)
        }else{
            setAmoveStorageFiltered([])
        }
    }, [amoveStorage]);
    const regionDeleteCancel = () => {
        setDeleteRegion(false);
        setRegionToDelete({})
    };
    const regionDeleteOpen = () => {
        setDeleteRegion(true);
    };
    const onChangeRegoin = (value) => {
        setSelectedRegions(value);
        console.log(value)
    };

    const onChangeRegoinFilter = (value) => {
        setSelectedRegionsFilter(value);
        console.log(value)
        if (value !== "all") {
            amoveStorage.map((item) => {
                if (item.id === value) {
                    var filtered = []
                    setTotalBuckets(item.buckets.length);
                    filtered.push(item)
                    setAmoveStorageFiltered(filtered)
                }
            })
        } else {
            var tBuckets = 0;
            amoveStorage.map((item) => {
                tBuckets += item.buckets.length;
            })
            setTotalBuckets(tBuckets)
            setAmoveStorageFiltered(amoveStorage)
        }
    };

    function deleteAmoveRegion(item) {
        var id = item.id;
        deleteDataWeb('/api/cloudaccount/idrive_delete_storage?id=' + id)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {
                if (status === 401) {
                    setEndpoint("/api/cloudaccount/idrive_delete_storage")
                    setShowPopupSupport(true)
                    //  logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }
                if (status === 499) {
                    var response = JSON.parse(data)
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                if (status === 200) {
                    let newArr = [...amoveStorageFiltered];
                    newArr = newArr.filter((i) => i.id!== id);
                    setAmoveStorageFiltered(newArr);
                    var tBuckets = 0;
                    newArr.map((item) => {
                        tBuckets += item.buckets.length;
                    })
                    setTotalBuckets(tBuckets)
                    setDeleteRegion(false);
                    setRegionToDelete({})
                    api.open({
                        message: 'Amove storage region removed successfully.',
                        description: `Your Amove storage region has been removed.`,
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5
                    });
                }
            });

    }

    const setUpNewIDrive = () => {
        var dataToPost = {
            "userEmail": "",
            "cloudAccountName": "Amove",
            "bucketTitle": "amovebucket",
            "region": selectedRegions,
            "defaultPassword": "",
            "shared": false
        }
        setLoadingAmove(true)
        getDataWithTokenPOST("/api/cloudaccount/idrive_add_storage", dataToPost)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                setLoadingAmove(false);
                if (status === 401) {
                    setEndpoint("/api/cloudaccount/idrive_add_storage")
                    setShowPopupSupport(true)
                    // logout()
                    setLoadingAmove(false);
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoadingAmove(false);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    setLoadingAmove(false);
                } else {
                    api.open({
                        message: `New Amove Storage Created.`,
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5
                    });
                    setLoadingAmove(false);
                    setShowRegion(false);
                    getCloudAccounts()
                }
            })

    };

    return (
        <>
            {contextHolder}
            <div id="main-content">
                <div className="container-fluid">
                    <div className="storage-dashboard-main">
                        <div className="storage-region">
                            <h3>Dashboard</h3>
                            {amoveStorage.length > 1 &&
                                <Select
                                    className={` ${regionsFilter !== '' && regionsFilter !== null ? "selected" : ''}`}
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={onChangeRegoinFilter}
                                    placeholder="Select a Region"
                                    value={selectedRegionsFilter}
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    options={regionsFilter}
                                />
                            }
                        </div>
                        <div className="dashboard-object-main">
                            <div className="object-wrapper">
                                <div className="object-card">
                                    <div className="header">
                                        <h3>All Buckets</h3>
                                        <div className="icon-wrapper">
                                            <img src={objectBucketIcon} alt="icon"/>
                                        </div>
                                    </div>
                                    <div className="body">
                                        {showPageData &&
                                            <strong>{totalBuckets}</strong>
                                        }
                                    </div>
                                    <div className="footer">
                                        <a href="#" className="btn-style" onClick={(e)=>{
                                            e.preventDefault()
                                            navigate("/buckets?create-bucket=true")
                                        }}>Create a Bucket</a>
                                    </div>
                                </div>
                                <div className="object-card">
                                    <div className="header">
                                        <h3>Size</h3>
                                        <div className="icon-wrapper">
                                            <img src={objectCircleIcon} alt="icon"/>
                                        </div>
                                    </div>
                                    <div className="body">
                                        {showPageData &&
                                            <strong>{prettySize(iDriveUsage)}</strong>
                                        }
                                    </div>
                                </div>
                                {/*<div className="object-card">
                                    <div className="header">
                                        <h3>Usage</h3>
                                        <div className="icon-wrapper">
                                            <img src={objectFolderIcon} alt="icon"/>
                                        </div>
                                    </div>
                                    <div className="body">
                                        <strong>40</strong>
                                    </div>
                                </div>*/}
                            </div>
                            <p>Storage usage and object count are not real-time and may take up to 24 hours to update.</p>
                        </div>
                        <div className="enable-region-wrapper">
                            <h3>Enabled Regions</h3>
                            {amoveStorageFiltered.length > 0 &&
                                <div className="table-wrapper">
                                    <div className="table-header grid-3">
                                        <p>Name</p>
                                        <p>S3 Endpoint</p>
                                        <p></p>
                                    </div>
                                    <div className="table-body">
                                        {amoveStorageFiltered.map((item) => (
                                            <div className="table-row grid-3">
                                                <div className="table-item">
                                                    <div className="name-wrapper">
                                                        {(item.name.substring(7, (item.name.length - 1)) === "Oregon" ||
                                                                item.name.substring(7, (item.name.length - 1)) === "Los Angeles" ||
                                                                item.name.substring(7, (item.name.length - 1)) === "Virginia" ||
                                                                item.name.substring(7, (item.name.length - 1)) === "Dallas" ||
                                                                item.name.substring(7, (item.name.length - 1)) === "Phoenix" ||
                                                                item.name.substring(7, (item.name.length - 1)) === "San Jose") &&
                                                            <img src={usFlag} alt="icon"/>
                                                        }

                                                        {item.name.substring(7, (item.name.length - 1)) === "London" &&
                                                            <img src={ukFlag} alt="icon"/>
                                                        }

                                                        {item.name.substring(7, (item.name.length - 1)) === "Frankfurt-2" &&
                                                            <img src={gmFlag} alt="icon"/>
                                                        }

                                                        {item.name.substring(7, (item.name.length - 1)) === "Paris" &&
                                                            <img src={frFlag} alt="icon"/>
                                                        }

                                                        {item.name.substring(7, (item.name.length - 1)) === "Ireland" &&
                                                            <img src={eiFlag} alt="icon"/>
                                                        }

                                                        {item.name.substring(7, (item.name.length - 1)) === "Montreal" &&
                                                            <img src={caFlag} alt="icon"/>
                                                        }


                                                        <p>{item.name.substring(7, (item.name.length - 1))}</p>
                                                    </div>
                                                </div>
                                                <div className="table-item">
                                                    <div className="copy-wrapper">
                                                        <p>{item.serviceUrl}</p>
                                                        <a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            navigator.clipboard.writeText(item.serviceUrl);
                                                            api.open({
                                                                message: 'S3 Endpoint Copied.',
                                                                icon: <img src={toastIcon} alt="toastIcon"/>,
                                                                duration: 3,
                                                            });
                                                        }}><img src={copyIcon} alt="icon"/></a>
                                                    </div>
                                                </div>
                                                <div className="table-item">
                                                    <ul className="options">
                                                        <li><a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            setRegionToDelete(item)
                                                            regionDeleteOpen(true)
                                                        }}><img src={trashIcon} alt="icon"/></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                            <div className="new-region-wrapper">
                                <a href="#" className="btn-style" onClick={onShowRegion}>Add new Region</a>
                                {(showRegion) &&
                                    <Select
                                        className={` ${regions !== '' && regions !== null ? "selected" : ''}`}
                                        showSearch
                                        optionFilterProp="children"
                                        onChange={onChangeRegoin}
                                        placeholder="Select a Region"
                                        value={selectedRegions}
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        options={regions.map((item) => ({
                                            label: item.name, value: item.code,
                                        }))}
                                    />
                                }
                               {(showRegion) &&
                                   <a href="#" className={`brand-icon ${selectedRegions === 'Select a Region' || loadingAmove ? 'disabled' : ''}`} onClick={(e) => {
                                       e.preventDefault();
                                       setUpNewIDrive()
                                   }}>
                                       {loadingAmove &&
                                           <Spin indicator={<LoadingOutlined style={{fontSize: 22}} spin/>}/>
                                       }
                                       <img src={isDark ? pageLogo : pageLogoWhite} alt="logo"/></a>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal title="Delete Region" className="bucket-main-modal" centered open={deleteRegion} onOk={regionDeleteCancel}
                   onCancel={regionDeleteCancel}>
                <div className="bucket-modal-header">
                    <div className="row">
                        <div className="col-8">
                            <div className="name-wrapper">
                                <img src={trashIcon} alt="icon"/>
                                <h3>Delete Bucket</h3>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="cross">
                                <a href="#" onClick={(e)=>{
                                    e.preventDefault();
                                    regionDeleteCancel()
                                }}>
                                    <img src={crossIcon} alt="icon"/></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bucket-modal-body">
                    <div className="trash-wrapper">
                        <p>Are you sure you want to delete this region?
                            This action cannot be undone, and all data within
                            the region will be permanently lost. Please confirm
                            if you wish to proceed.</p>
                    </div>
                </div>
                <div className="bucket-modal-footer">
                    <ul>
                        <li><a href="#" className="btn-style trash" onClick={()=>{
                            deleteAmoveRegion(regionToDelete)
                        }}>Delete Bucket</a></li>
                        <li><a href="#" className="btn-style transparent" onClick={regionDeleteCancel}>Cancel</a></li>
                    </ul>
                </div>
            </Modal>
        </>
    )
}

export default StorageDashboard;
