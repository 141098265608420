import React, {useState, useRef, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Checkbox, Modal, notification, Select, Tooltip} from 'antd';
import toastIcon from "../assets/images/icons/toast-icon.svg";
import config from "../config";
import {useAuth} from "../context/AuthContext";
import pageLogo from "../assets/images/logo.svg";
import warning from "../assets/images/icons/warning-sign.svg";

const AmoveStorage = ({setStep, popup = false, URLParms = ""}) => {
    const {
    token,
    drivesPopup,
    setDrivesPopup,
    checkAgent,
    isDark,
    sendDataToWebsocket,
     postDataWebNoResponse,
     setPopup500,
     logout,
     setShowPopupSupport,
     setEndpoint,
        sendDataToMixPanel,
        user,
        setError499,
        setPopup499
     } = useAuth();
    const navigate = useNavigate();
    const [newBucketName, setNewBucketName] = useState("");
    const [errorText, setErrorText] = useState('');
    const [loading, setLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    const validBucketName = (bucket) => {
        var regularExp = /^(?!^(\d{1,3}\.){3}\d{1,3}$)(^[a-z0-9]([a-z0-9\-]*[a-z0-9])?(?:\.[a-z0-9]([a-z0-9\-]*[a-z0-9])?)*$)/;
        return regularExp.test(bucket);
    }

    const setUpNewAmoveStorage = () => {
        var dataToPost = {
            "cloudAccountName": "Amove",
            "bucketName": "amovebucket",
            "shared": false
        }
        if (validBucketName(newBucketName) && newBucketName.length > 2 && newBucketName.length < 64) {

            setLoading(true)
            postDataWebNoResponse("/api/cloudaccount/add_storage", dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, response]) => {
                    setLoading(false);
                    if (status === 401) {
                        setEndpoint("/api/cloudaccount/add_storage")
                        setShowPopupSupport(true)
                       // logout()
                        setLoading(false);
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var resp = JSON.parse(response)
                        var errorType = resp.type;
                        setError499(resp.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    if (status === 499) {
                        if (response.type === 'DEFAULT') {
                            setErrorText(response.errors.DEFAULT[0])
                        }
                        setLoading(false);
                    }
                    if (status === 200) {
                        api.open({
                            message: `New Amove Storage Created.`,
                            icon: <img src={toastIcon} alt="toastIcon"/>,
                            duration: 5
                        });

                        sendDataToMixPanel('New Amove Storage Created.', user.username, {
                            'App': 'Web App'
                        })

                        setLoading(false);
                        if (URLParms !== "") {
                            setDrivesPopup("true");
                            navigate(`/dashboard?amove=true&drive=true`);
                        } else {
                            navigate("/dashboard");
                        }
                    }
                })
        }
    };
    return (
        <>
            {contextHolder}
            <img src={pageLogo} alt="logo"/>
            <h2>Create Storage</h2>
            <p>Name your first bucket and gain instant access to <br/>
                high performance storage from your desktop.</p>
            <div className="form storage-from">
                <div className="form-group">
                    <input type="text" placeholder="myamovebucket" value={newBucketName} onChange={(e) => {
                        setNewBucketName(e.target.value);
                        setErrorText("");
                    }} className="form-control"/>
                </div>
                <span className={`small-text ${newBucketName.length > 1 ? "show" : ""}`}>Use lower case letters. No special characters or spaces.</span>
            </div>

            {errorText !== "" &&
                <div className="form storage-from">
                    <span className="red-error">{errorText}</span>
                </div>
            }
            <Button loading={loading} className={`btn-style blue mr-1`} onClick={() => {
                if (newBucketName !== "" && validBucketName(newBucketName) && newBucketName.length > 3 && newBucketName.length < 64) {
                    setUpNewAmoveStorage()
                }
            }}>Create Storage</Button>

        </>
    )
}
export default AmoveStorage;
