import {React, useEffect, useState, useRef} from 'react';
import {CloudAccounts} from "../components/CloudAccounts";
import {Navigate, useSearchParams, useNavigate} from "react-router-dom";
import {Button, Dropdown, Modal, Radio, Select, Tooltip, Tour} from "antd";
import {useAuth} from "../context/AuthContext";
import {CloudDrives} from "../components/CloudDrives";
import {BackupInstance} from "../components/BackupInstance";
import {ProjectsAccordion} from "../components/ProjectsAccordion";
import Icon, {CloseOutlined} from '@ant-design/icons';
import pageLogoWhite from "../assets/images/logo-dark.svg";
import pageLogo from "../assets/images/logo.svg";
import config from "../config";
import popupImage from "../assets/images/amove-setup-min.jpg";
import crossBalckIcon from "../assets/images/icons/tour-cross-black-icon.svg";

const Dashboard = () => {
    const [searchParams] = useSearchParams();
    const subscriptionStatus = searchParams.get('subscription');
    const {
        getData,
        user,
        userStatus,
        amovePkg,
        showAgentOnce,
        setShowAgentOnce,
        isDark,
        checkAgent,
        setShowHeaderAndSidebar,
        isWindows,
        showTourDashboard,
        setShowTourDashboard,
        totalDrives,
        tourStep,
        setTourStep,
        agentIsRunning,
        showStartedBtn,
        setShowStartedBtn,
        sendDataToMixPanel,
        token,
        cloudAccounts,
        defaultOpenCloudDrive,
        isAuthenticated,
        setShowDrivePopup2,
        showLaunchPopup,
        setShowLaunchPopup
    } = useAuth();

    //creating a reference object
    const countRef = useRef(agentIsRunning);
    countRef.current = agentIsRunning;

    const navigate = useNavigate();
    const [firstPopup, setFirstPopup] = useState(false);
    const dataFetchedRef = useRef(false);
    const urlParams = new URLSearchParams(window.location.search);
    const amoveDrive = urlParams.get('amove')
    const onboarding = urlParams.get('onboarding')
    const accounts = urlParams.get('accounts')
    const lastid = urlParams.get('lastid');

    const [showButtons, setShowButtons] = useState(false);
    const [driveCount, setDriveCount] = useState(0);
    const [showSingleButton, setShowSingleButton] = useState(false);
    const [drivesOpen, setDrivesOpen] = useState(false);
    const [accountsOpen, setAccountsOpen] = useState(false);
    const [syncOpen, setSyncOpen] = useState(false);
    const [standardUserTour, setStandardUserTour] = useState(false);
    const OnboardingAgentOk = () => {
        setShowAgentOnce(false);
        if (amoveDrive === "true") {
            localStorage.setItem("dashboardTourStep", 1)
            setShowTourDashboard(true)
        }
    };
    const loadCloudDrives = () => {
        getData('/Management/clouddrive')
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                setDriveCount(response.length)
                if (response.length > 0) {
                    setShowStartedBtn(false)
                } else {
                    setShowStartedBtn(true)
                }
            }).catch((error) => {
            setShowStartedBtn(true)
        })
    }
    useEffect(() => {
        if(agentIsRunning){
            loadCloudDrives();
            setStandardUserTour(false)
        }else if(user.userType === 64){
            localStorage.setItem('shorNextTour',false)
        }
        if(!agentIsRunning){
            setShowStartedBtn(true)
        }
    }, [agentIsRunning]);

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        setShowHeaderAndSidebar(true);
        setTimeout(function () {
            if (amoveDrive === "true") {
                localStorage.setItem("dashboardTourStep", 1)
                setShowTourDashboard(true)
            }
        }, 2000)
        checkAgent()
        checkAgentInterval()
    }, []);

    var agentCallCount = 0
    function checkAgentInterval() {
        console.log("callAgentAgain")
        console.log(countRef.current)
        agentCallCount++
        var callAgentAgain = setTimeout(() => {
            checkAgentInterval()
        }, 2000);

        if (localStorage.getItem('isLoggedInTimer') === null || agentCallCount === 10 || countRef.current) {
            console.log("timer cleared checkAgentInterval ----------------")
            agentCallCount=0
            clearTimeout(callAgentAgain);
        }else{
            checkAgent();
        }
    }


    useEffect(() => {
        if(user.userType === 64){
            if(!agentIsRunning) {
                setStandardUserTour(true)
            }
        }
    }, [user]);
    if (subscriptionStatus === 'unsuccess') {
        return <Navigate to="/cancel-subscribe"/>;
    }

    function startTourAgain() {
        setShowTourDashboard(true);
        setShowStartedBtn(true);
    }

    function accordionCheck(data) {
        Object.entries(data).find(([key, val]) => {
            if (key === 'drives') {
                setDrivesOpen(val)
            }
            if (key === 'accounts') {
                setAccountsOpen(val)
            }
            if (key === 'sync') {
                setSyncOpen(val)
            }
        })
    }

    return (
        <>
            <div id="main-content" className={`${showTourDashboard ? "tour-index" : ""} ${tourStep === 5 && showTourDashboard ? "step-5" : ""}`}>
                <div className="container-fluid">
                    {(userStatus.status === "trial" || userStatus.status === "active") &&
                        <div className="main-content-body home dashboard-page">
                            {user.userType !== 64 && user.userType !== undefined &&
                                <ProjectsAccordion/>
                            }
                            <CloudDrives callback={accordionCheck}/>
                            {user.userType !== 64 && user.userType !== undefined &&
                                <div className="new-pop-wrapper" id="drives-accordion-popup">
                                    <CloudAccounts open={amoveDrive === "true" || accounts === "true" ? 1 : 0} lastid={lastid} onboarding={amoveDrive === "true" || onboarding} callback={accordionCheck}/>
                                    <Tooltip
                                        placement="right"
                                        open={firstPopup}
                                        getPopupContainer={() => document.getElementById("drives-accordion-popup")}
                                        title={<div className="tooltip-new-popup">
                                            <a href="#" className="close-icon" onClick={(e) => {
                                                setFirstPopup(false)
                                                e.preventDefault()
                                            }}>
                                                <CloseOutlined/>
                                            </a>
                                            <span className="title">Amove Storage Now Available</span>

                                            <p>25GB is now available directly from your Amove Drive. <br/>
                                                1TB free for each user with an Amove Everywhere Subscription.
                                            </p>

                                            {amovePkg === "premium" &&
                                                <p>25GB is now available directly from your Amove Drive. <br/>
                                                    3TB free for each user with your Amove Premium subscription.</p>
                                            }
                                        </div>}>
                                    </Tooltip>
                                </div>
                            }
                            {user.userType !== 64 && user.userType !== undefined &&
                                <BackupInstance callback={accordionCheck}/>
                            }
                        </div>
                    }
                    {/*Agent Popup*/}
                    {(amoveDrive === "true" || showAgentOnce) &&
                        <Modal
                            open={showAgentOnce} onOk={OnboardingAgentOk} onCancel={OnboardingAgentOk}
                            centered
                            className="download-client-popup once-agent"
                            footer={null}
                            title={null}
                        >
                            <div className="cloud-drive-modal-wrapper new-on-boarding-popup">
                                <div className="row row-eq-height">
                                    <div className="col-md-4 ">
                                        <div className="left-side">
                                            <div className="logo-img-wrapper">
                                                <img src={pageLogo} alt="logo"/>
                                            </div>
                                            <div className="content-wrapper">
                                                <h3 className="title">Your 25 GB of Amove <br/> storage is now available.</h3>
                                                <h3>Let's download the Amove Desktop <br/> Client in order to gain instant access.</h3>
                                                <h3>To connect additional cloud <br/> accounts to Amove click <a href="#" onClick={() => {

                                                    setShowAgentOnce(false);
                                                    setShowAgentOnce(false);
                                                    setShowTourDashboard(false)
                                                    localStorage.setItem("dashboardTourStep", 0)
                                                    navigate("/add-cloud-account");
                                                }}>here</a>.</h3>
                                            </div>

                                            <div className="text-center launch-amove-btn">

                                                {config.environment === 'POC' && !showSingleButton &&
                                                    <>
                                                        {!isWindows &&
                                                            <a href='#' onClick={(e) => {
                                                                e.preventDefault();
                                                                checkAgent(true)
                                                                setShowButtons(false)
                                                                setShowTourDashboard(false)
                                                                sendDataToMixPanel('Amove-intel-POC downloaded.', user.username, {
                                                                    'App': 'Web App'
                                                                })

                                                                // Create a temporary link element
                                                                const link = document.createElement('a');
                                                                link.href = "https://d2h7a876wzviri.cloudfront.net/Amove-intel-POC.pkg";
                                                                link.download = "Amove-Mac-latest.pkg";
                                                                link.target = "_blank";

                                                                // Append the link to the body
                                                                document.body.appendChild(link);

                                                                // Trigger the download by clicking the link
                                                                link.click();

                                                                // Remove the link from the document
                                                                document.body.removeChild(link);
                                                            }} className="btn-style invert">Download Amove</a>
                                                        }
                                                        {isWindows &&
                                                            <a href='#' onClick={(e) => {
                                                                e.preventDefault();
                                                                checkAgent(true)
                                                                setShowButtons(false)
                                                                setShowTourDashboard(false)
                                                                sendDataToMixPanel('Amove-64bit-POC downloaded.', user.username, {
                                                                    'App': 'Web App'
                                                                })
                                                                // Create a temporary link element
                                                                const link = document.createElement('a');
                                                                link.href = "https://d2h7a876wzviri.cloudfront.net/Amove-64bit-POC.exe";
                                                                link.download = "Amove-Mac-latest.pkg";
                                                                link.target = "_blank";

                                                                // Append the link to the body
                                                                document.body.appendChild(link);

                                                                // Trigger the download by clicking the link
                                                                link.click();

                                                                // Remove the link from the document
                                                                document.body.removeChild(link);
                                                            }} className="btn-style invert">Download Amove</a>
                                                        }
                                                    </>
                                                }
                                                {config.environment !== 'POC' && !showSingleButton &&
                                                    <>
                                                        {!isWindows &&
                                                            <a href='#' onClick={(e) => {
                                                                e.preventDefault();
                                                                checkAgent(true)
                                                                setShowButtons(false)
                                                                setShowTourDashboard(false)
                                                                sendDataToMixPanel('Amove-Mac-latest downloaded.', user.username, {
                                                                    'App': 'Web App'
                                                                })

                                                                // Create a temporary link element
                                                                const link = document.createElement('a');
                                                                link.href = "https://d2h7a876wzviri.cloudfront.net/Amove-Mac-latest.pkg";
                                                                link.download = "Amove-Mac-latest.pkg";
                                                                link.target = "_blank";

                                                                // Append the link to the body
                                                                document.body.appendChild(link);

                                                                // Trigger the download by clicking the link
                                                                link.click();

                                                                // Remove the link from the document
                                                                document.body.removeChild(link);
                                                            }} className="btn-style invert">Download Amove</a>
                                                        }
                                                        {isWindows &&
                                                            <a href='#' onClick={(e) => {
                                                                e.preventDefault();
                                                                checkAgent(true)
                                                                setShowButtons(false)
                                                                setShowTourDashboard(false)
                                                                sendDataToMixPanel('Amove-Win-latest downloaded.', user.username, {
                                                                    'App': 'Web App'
                                                                })
                                                                // Create a temporary link element
                                                                const link = document.createElement('a');
                                                                link.href = "https://d2h7a876wzviri.cloudfront.net/Amove-Win-latest.exe";
                                                                link.download = "Amove-Mac-latest.pkg";
                                                                link.target = "_blank";

                                                                // Append the link to the body
                                                                document.body.appendChild(link);

                                                                // Trigger the download by clicking the link
                                                                link.click();

                                                                // Remove the link from the document
                                                                document.body.removeChild(link);
                                                            }} className="btn-style invert">Download Amove</a>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="right-side">
                                            <img src={`${popupImage}`} alt="popupImage"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    }

                    {user.userType === 64 && !agentIsRunning && standardUserTour && !showLaunchPopup &&
                        <div className="standard-user-tour">
                            <div className="tour-overlay" onClick={(e) => {
                                e.preventDefault();
                                setStandardUserTour(false)
                            }}></div>
                            <div className={`tour-tooltip tour-create-drive tour-step-5 active`}>
                                <div className="tour-tooltip-wrapper">
                                    <a href="#" className="cross" onClick={(e) => {
                                        e.preventDefault();
                                        setStandardUserTour(false)
                                    }}>
                                        <img src={crossBalckIcon} alt="icon"/>
                                    </a>
                                    <h3>Access your drive</h3>
                                    <ul className="steps">
                                        <li className="active"><span className="dot"></span></li>
                                        <li className="active"><span className="dot"></span></li>
                                        <li className="active"><span className="dot"></span></li>
                                    </ul>
                                    <div className="launch-amove">
                                        <p className="p black bold">Instant Connectivity for Everyone</p>
                                        <p className="p black">Set up unlimited Connections, Drives, Syncs and manage
                                            all of your teams and content instantly, from anywhere.</p>

                                        <p className="p black bold">Download the Amove Desktop App</p>
                                        <p className="p black">If you have not installed the Amove app, please do so:</p>


                                        <div className="btn-wrapper">
                                            {config.environment === 'POC' && !showSingleButton &&
                                                <>
                                                    {!isWindows &&
                                                        <a href='#' onClick={(e) => {
                                                            e.preventDefault();
                                                            checkAgent(true)
                                                            setShowSingleButton(true)
                                                            sendDataToMixPanel('Amove-intel-POC downloaded.', user.username, {
                                                                'App': 'Web App'
                                                            })
                                                            setStandardUserTour(false)

                                                            // Create a temporary link element
                                                            const link = document.createElement('a');
                                                            link.href = "https://d2h7a876wzviri.cloudfront.net/Amove-intel-POC.pkg";
                                                            link.download = "Amove-Mac-latest.pkg";
                                                            link.target = "_blank";

                                                            // Append the link to the body
                                                            document.body.appendChild(link);

                                                            // Trigger the download by clicking the link
                                                            link.click();

                                                            // Remove the link from the document
                                                            document.body.removeChild(link);
                                                        }} className="btn-tour orange">Download Amove</a>
                                                    }
                                                    {isWindows &&
                                                        <a href='#' onClick={(e) => {
                                                            e.preventDefault();
                                                            checkAgent(true)
                                                            setShowSingleButton(true)
                                                            setStandardUserTour(false)
                                                            sendDataToMixPanel('Amove-64bit-POC downloaded.', user.username, {
                                                                'App': 'Web App'
                                                            })

                                                            // Create a temporary link element
                                                            const link = document.createElement('a');
                                                            link.href = "https://d2h7a876wzviri.cloudfront.net/Amove-64bit-POC.exe";
                                                            link.download = "Amove-Mac-latest.pkg";
                                                            link.target = "_blank";

                                                            // Append the link to the body
                                                            document.body.appendChild(link);

                                                            // Trigger the download by clicking the link
                                                            link.click();

                                                            // Remove the link from the document
                                                            document.body.removeChild(link);
                                                        }} className="btn-tour orange">Download Amove</a>
                                                    }
                                                </>
                                            }
                                            {config.environment !== 'POC' && !showSingleButton &&
                                                <>
                                                    {!isWindows &&
                                                        <a
                                                            href="#"
                                                            className="btn-tour fix-width orange"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                checkAgent(true);
                                                                setShowSingleButton(true);
                                                                setStandardUserTour(false);
                                                                sendDataToMixPanel('Amove-Mac-latest downloaded.', user.username, {
                                                                    'App': 'Web App'
                                                                });

                                                                // Create a temporary link element
                                                                const link = document.createElement('a');
                                                                link.href = "https://d2h7a876wzviri.cloudfront.net/Amove-Mac-latest.pkg";
                                                                link.download = "Amove-Mac-latest.pkg";
                                                                link.target = "_blank";

                                                                // Append the link to the body
                                                                document.body.appendChild(link);

                                                                // Trigger the download by clicking the link
                                                                link.click();

                                                                // Remove the link from the document
                                                                document.body.removeChild(link);
                                                            }}
                                                        >
                                                            Download Amove
                                                        </a>
                                                    }
                                                    {isWindows &&
                                                        <a
                                                            href="#"
                                                            className="btn-tour fix-width orange"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                checkAgent(true);
                                                                setShowSingleButton(true);
                                                                setStandardUserTour(false);
                                                                setShowDrivePopup2(true);
                                                                sendDataToMixPanel('Amove-Win-latest downloaded.', user.username, {
                                                                    'App': 'Web App'
                                                                });

                                                                // Create a temporary link element
                                                                const link = document.createElement('a');
                                                                link.href = "https://d2h7a876wzviri.cloudfront.net/Amove-Win-latest.exe";
                                                                link.download = "Amove-Mac-latest.pkg";
                                                                link.target = "_blank";

                                                                // Append the link to the body
                                                                document.body.appendChild(link);

                                                                // Trigger the download by clicking the link
                                                                link.click();

                                                                // Remove the link from the document
                                                                document.body.removeChild(link);
                                                            }}
                                                        >
                                                            Download Amove
                                                        </a>
                                                    }
                                                </>
                                            }
                                        </div>

                                        <p className="p black bold">Launch the Amove Desktop App</p>
                                        <p className="p black">If you already have the app installed, simply launch it:</p>

                                        <div className="btn-wrapper">
                                            <a href='#' onClick={(e) => {
                                                e.preventDefault();
                                                sendDataToMixPanel('Launch the Amove Desktop App', user.username, {
                                                    'App': 'Web App'
                                                })
                                                checkAgent(true)
                                                window.location.href = `amoveapp://open?token=${token}`;

                                            }} className={`btn-tour`}>Launch Amove</a>
                                        </div>
                                    </div>
                                    {/*<div className="amove-connectivity">
                        <p className="p black bold">Instant Connectivity for Everyone</p>
                        <p className="p black">Setup unlimited Connetions, Drives, Projects
                            and manage ALL of your teams and content instantly, from anywhere.</p>
                    </div>*/}
                                </div>
                            </div>
                        </div>
                    }


                </div>
            </div>
            {user.userType !== 64 && user.userType !== undefined && showStartedBtn && !syncOpen && !accountsOpen && !drivesOpen && !showTourDashboard && cloudAccounts.length > 0 &&
                <a href="#" className="getting-started-link btn-style small orange" onClick={startTourAgain}>Guide</a>
            }
        </>
    )
}

export default Dashboard;

